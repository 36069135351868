import {
  ADD_CLUB_START,
  ADD_CLUB_SUCCESS,
  ADD_CLUB_FAIL,
  EDIT_DETAILS_START,
  EDIT_DETAILS_SUCCESS,
  EDIT_DETAILS_FAIL,
  API_SUCCESS_OTHER,
  API_FAIL_OTHER,
  API_START_OTHER,
  // API_FAIL,
  // API_START,
  // API_SUCCESS,
  API_START_LISTING,
  API_SUCCESS_LISTING,
  API_FAIL_LISTING,
  ADD_EDIT_LICENCE_START,
  ADD_EDIT_LICENCE_FAIL,
  ADD_EDIT_LICENCE_SUCCESS,
  RELEASE_CHANNEL_DETAILS_START,
  RELEASE_CHANNEL_DETAILS_SUCCESS,
  RELEASE_CHANNEL_DETAILS_FAIL,
} from "../../../constants/type";
import API from "../../utils/api";
import config from "../../../constants/config";
import UtilService from "../../utils/utils";
// import constant from "../../../constants/constant";
import myApi from "../../../container/utils/api";



export const clubAction = (stateData, isClubEditApiSuccess) => {
  return function (dispatch) {
    
    //console.log("Status Id=" + UtilService.getUserDetail('statusId'))
    let orgInfo = stateData.orgInfo
    let addressField = stateData.addressField
    let addLicence = stateData.addLicence
    let pointOfContact = stateData.pointOfContact
    dispatch({ type: ADD_CLUB_START });
    let dataParams = {
      channels: stateData.channel,
      club: {
        clubId: stateData.clubId,
        alternateCountryCode: orgInfo.alternateCountryCode,
        city: addressField.city,
        clubStatusId: stateData.status,
        contactNumber: orgInfo.contactNumber,
        countryCode: orgInfo.countryCode,
        countryId: stateData.selectedCountryId,
        mobile: orgInfo.mobile,
        name: orgInfo.clubName,
        state: addressField.state,
        streetAddress1: addressField.streetAddress1,
        streetAddress2: addressField.streetAddress2,
        zip: addressField.zip,
        platformFee:orgInfo.platformFee,
        transactionFee:orgInfo.transactionFee,
        monthlyChargeDay:orgInfo.monthlyChargeDay,
        monthlyCharge:orgInfo.monthlyCharge,
        isAffiliated :orgInfo.isAffiliated,
      },
      licence: {
        id: stateData.licenceId,
        endDate: UtilService.getDateIntoTimeStampInMillSec(stateData.endDate),
        numberOfLicence: addLicence.noOfLicence,
        startDate: UtilService.getDateIntoTimeStampInMillSec(stateData.startDate)
      },
      user: {
        userId: stateData.userId,
        email: pointOfContact.email,
        currency: orgInfo.currencyId,
        fullName: pointOfContact.ownerName,
        pocCountryCode: pointOfContact.poc_country_code,
        pocMobile: pointOfContact.pointOfPhoneNumber,
        roleId: pointOfContact.roleId,
        statusId: pointOfContact.statusId,
        pocSlackId:pointOfContact.pocSlackId
      }
    };
    //console.log("ClubAdd-Update=" + JSON.stringify(dataParams))
    var addUpdateClubResponse = null
    if (isClubEditApiSuccess)
      addUpdateClubResponse = API.putRequest(`${config.BASE_URL}/club`, dataParams)
    else
      addUpdateClubResponse = API.postRequest(`${config.BASE_URL}/club`, dataParams)

    addUpdateClubResponse.then(response => {
      //success API response
      return dispatch({
        type: ADD_CLUB_SUCCESS,
        payload: response.message
      });

    })
      .catch((error) => {
        //failed Api response
        if (error.response) {
          return dispatch({ type: ADD_CLUB_FAIL, payload: error.response.data.message });
        } else {
          return dispatch({ type: ADD_CLUB_FAIL, payload: error });
        }
      });
  };
};

export const addChannelAction = (requestPayload) => {
  return function (dispatch) {
    dispatch({ type: EDIT_DETAILS_START });
    //console.log("ClubAdd-Update=" + JSON.stringify(requestPayload))
    var addUpdateClubResponse = null
    addUpdateClubResponse = API.postRequest(`${config.BASE_URL}/channel`, requestPayload)
    addUpdateClubResponse.then(response => {
      //success API response
      //console.log(response)
      return dispatch({
        type: EDIT_DETAILS_SUCCESS,
        payload: response.message
      });

    })
      .catch((error) => {
        //failed Api response
        if (error.response) {
          return dispatch({ type: EDIT_DETAILS_FAIL, payload: error.response.data.message });
        } else {
          return dispatch({ type: EDIT_DETAILS_FAIL, payload: error });
        }
      });
  };
};

/*Below function is used to get all channel list using clubId*/
export const getChannelListAction = (clubId) => {
  return async function (dispatch) {
    dispatch({ type: API_START_OTHER });
    await API
      .getRequest(`${config.BASE_URL}/channel/club/${clubId}`)
      .then(response => {
        dispatch({ type: API_SUCCESS_OTHER, payload: response });
      })
      .catch(error => {
        dispatch({ type: API_FAIL_OTHER, payload: error });
      });
  };
}


/*Below function is used to edit the channel 
*/
export const editChannelAction = (requestPayload) => {
  return function (dispatch) {
    dispatch({ type: EDIT_DETAILS_START });
    //console.log("edit-Update=" + JSON.stringify(requestPayload))
    var addUpdateClubResponse = null
    addUpdateClubResponse = API.putRequest(`${config.BASE_URL}/channel`, requestPayload)
    addUpdateClubResponse.then(response => {
      //success API response
      //console.log(response)
      return dispatch({
        type: EDIT_DETAILS_SUCCESS,
        payload: response.message
      });

    })
      .catch((error) => {
        //failed Api response
        if (error.response) {
          return dispatch({ type: EDIT_DETAILS_FAIL, payload: error.response.data.message });
        } else {
          return dispatch({ type: EDIT_DETAILS_FAIL, payload: error });
        }
      });
  };
};

/*Below function is use to delete the channel 
*/
export const deleteChannelAction = (channelId) => {
  return function (dispatch) {
    //need to change url for delete channel
    dispatch({ type: EDIT_DETAILS_START });
    API.deleteRequest(`${config.BASE_URL}/channel/` + channelId)
      .then(response => {
        return dispatch({
          type: EDIT_DETAILS_SUCCESS,
          payload: response
        });
      })
      .catch(error => {
        //failed Api response
        return dispatch({ type: EDIT_DETAILS_FAIL, payload: error });
      });
  }
}
/*
Below function is used to get the listing of licences
*/
export const getLicenceList = (clubId) => {

  return dispatch => {
    dispatch({ type: API_START_LISTING });
    API
      .getRequest(`${config.BASE_URL}/licence/club/${clubId}`)
      .then(response => {
        return dispatch({ type: API_SUCCESS_LISTING, payload: response });
      })
      .catch(error => {
        return dispatch({ type: API_FAIL_LISTING, payload: error.response });
      });

  }

};

/* The below function Action is add and Edit License.
*/
export const addEditLicenceAction = (data, isEdit) => {
  return dispatch => {
    dispatch({ type: ADD_EDIT_LICENCE_START });
    let apiRequest;
    if (isEdit) {
      apiRequest = API.putRequest(`${config.BASE_URL}/licence`, data)
    } else {
      apiRequest = API
        .postRequest(`${config.BASE_URL}/licence`, data)
    }

    apiRequest.then(response => {
      return dispatch({ type: ADD_EDIT_LICENCE_SUCCESS, payload: response });
    })
      .catch(error => {
        return dispatch({ type: ADD_EDIT_LICENCE_FAIL, payload: error.response });
      });
  }
};


/*Below function is use to delete the channel 
*/
export const deleteLicenceAction = (licenceId) => {
  return function (dispatch) {
    //need to change url for delete channel
    dispatch({ type: ADD_EDIT_LICENCE_START });
    API.deleteRequest(`${config.BASE_URL}/licence/` + licenceId)
      .then(response => {
        return dispatch({
          type: ADD_EDIT_LICENCE_SUCCESS,
          payload: response
        });
      })
      .catch(error => {
        //failed Api response
        return dispatch({ type: ADD_EDIT_LICENCE_FAIL, payload: error });
      });
  }
}

/* The below function Action is Renew  License.
*/
export const renewLicenceAction = (data, licenceId) => {
  return dispatch => {

    dispatch({ type: ADD_EDIT_LICENCE_START });
    API
      .postRequest(`${config.BASE_URL}/licence/${licenceId}/renew`, data).then(response => {
        return dispatch({ type: ADD_EDIT_LICENCE_SUCCESS, payload: response });
      })
      .catch(error => {
        return dispatch({ type: ADD_EDIT_LICENCE_FAIL, payload: error.response });
      });
  }
};

export const getCardsAction = channelId  => {
  return async function (dispatch) {
      dispatch({ type: RELEASE_CHANNEL_DETAILS_START });
      await myApi
          .putRequest(`${config.BASE_URL}/channel/release/${channelId}`)
          .then(response => {
              dispatch({ type: RELEASE_CHANNEL_DETAILS_SUCCESS, payload: response });
          })
          .catch(error => {
              dispatch({ type: RELEASE_CHANNEL_DETAILS_FAIL, payload: error });
          });
  };
};