import React, { Component } from 'react';
import PointOfContact from './pointOfContact';
import OrganizationInformation from './organizationInformation';
import LicenceManagement from './licenceManagement';
import constant from "../../../constants/constant";
import $ from 'jquery';
import UtilService from '../../utils/utils';
import { connect } from 'react-redux'
import { clubAction, addChannelAction, renewLicenceAction, deleteLicenceAction, getLicenceList, addEditLicenceAction, getChannelListAction, editChannelAction, deleteChannelAction } from './clubAction'
import { editClubAction } from "./clubManagementAction";
import { get } from 'lodash'
import CountryCodeSelect from '../../view/CountryCodeSelect';
import ResetAction from '../../../commonAction/ResetAction'
import { ErrorAlert } from '../../view/ErrorAlert';
import { countryApiAction } from '../../../commonAction/countryApiAction'
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import ValidationMessage from "../../view/ValidationMessage";
import { CLUB_EDIT_RESET, RESET } from '../../../constants/type'
import { sendMailAction } from '../../../commonAction/sendMailAction';
import { RESET_SEND_MAIL, EDIT_DELETE_RESET, ADD_CLUB_START } from '../../../constants/type'
import { SuccessAlert } from '../../view/SuccessAlert';
import AddChannel from './addChannel'
import LicenceView from './LicenceView';
import { toast } from "react-toastify";
const initialAddressStateKey = {
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: "",
    zip: "",
    countryId: ""
}
const ADD_CHANNEL = "ADD_CHANNEL"
const EDIT_CHANNEL = "EDIT_CHANNEL"
// const DELETE_CHANNEL = "DELETE_CHANNEL"
const ADD_LICENCE = "ADD_LICENCE"
const EDIT_LICENCE = "EDIT_LICENCE"
const RENEW_LICENCE = "RENEW_LICENCE"
class AddClub extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logo: null,
            orgInfo: {
                clubName: "",
                countryCode: "",
                mobile: "",
                alternateCountryCode: "",
                contactNumber: "",
                platformFee:"",
                transactionFee:"",
                monthlyChargeDay: '',
                monthlyCharge:'',
                isAffiliated: false,
            },
            addressField: initialAddressStateKey,
            pointOfContact: {
                ownerName: "",
                email: "",
                poc_country_code: "",
                pointOfPhoneNumber: "",
                roleId: constant.CLUB_SUPER_ADMIN_VALUE, //3,//CLUB_SUPER_ADMIN
                statusId: constant.PASSWORD_NOT_SET_VALUE, // 4 //PASSWORD_NOT_SET
                pocSlackId:""
            },
            addLicence: {
                noOfLicence: "",
            },
            channels: {
                channelName: "",
                channelKey: ""
            },
            submitted: false,
            errors: {},
            fillAddressData: '',
            clubId: 0,
            checked: false,
            userId: 0,
            status: 2,
            licenceId: 0,
            selectedCountryId: '',
            startDate: new Date(),
            endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            channel: [],
            isUpdateChannel: false,
            isEdit: false,
            noOfLicence: "",
            licenceId: "",
            isEditLicenceFieldDisable: false,
            isStartDateLicenceFieldDisable: false,

        }
        this.isClubEditApiSuccess = get(this.props, "editClubReducer.isClubEditApiSuccess", false);
        this.clubEditSuccessResponse = get(this.props, "editClubReducer.clubEditSuccessResponse", null);
        this.addressData = {}
        this.timeOut = null
        this.channelAction = ""
        this.actionType = null
        this.channelEditIndex = ""
    }

    async componentDidMount() {
        let states = this.props.location.state ? this.props.location.state.state : [];
        if (!this.isClubEditApiSuccess && states.actionName === constant.EDIT_CLUB) {
            await this.props.dispatch(editClubAction(states.clubId, states.actionName, this.props.history))
        }

        const { orgInfo, pointOfContact, addressField } = this.state;
        let countryApiData = await UtilService.getCountryList();
        if (!countryApiData) {
            await this.props.dispatch(countryApiAction())
            countryApiData = get(this.props, "countryApiReducer.countryApiData", null);
        }

        let initialCountryId = JSON.parse(countryApiData).countries[0].countryCode
        this.setState({
            orgInfo: {
                ...orgInfo,
                countryCode: initialCountryId,
                alternateCountryCode: initialCountryId,
            },
            pointOfContact: {
                ...pointOfContact,
                poc_country_code: initialCountryId
            },
            addressField: {
                ...addressField,
                countryId: JSON.parse(countryApiData).countries[0].countryName
            }

        })
        this.isClubEditApiSuccess = get(this.props, "editClubReducer.isClubEditApiSuccess", false);
        this.clubEditSuccessResponse = get(this.props, "editClubReducer.clubEditSuccessResponse", null);
        //Update/Edit Add Club data
        if (this.clubEditSuccessResponse !== null) {
            //set all data into component 
            this.setAddClubEditValue()
        }
        //Hit License get Api 
        if (this.isClubEditApiSuccess) {
            this.getLicenceList(this.clubEditSuccessResponse.club.clubId)
            // console.log("EditClubData", this.clubEditSuccessResponse.club.clubId)
            //  this.props.dispatch(getLicenceList(this.state.clubId))
        }
    }
    setAddClubEditValue = () => {

        const { orgInfo, pointOfContact, addLicence, addressField } = this.state;
        this.clubEditSuccessResponse = get(this.props, "editClubReducer.clubEditSuccessResponse", null);
        const { club, licence, user } = this.clubEditSuccessResponse
        const countryId = UtilService.getCountryName(club.countryId)

        this.setState({
            ...orgInfo,
            orgInfo: {
                clubName: club.name,
                countryCode: club.countryCode,
                mobile: club.mobile,
                alternateCountryCode: club.alternateCountryCode,
                contactNumber: club.contactNumber,
                currencyId: user.currency,
                platformFee:club.platformFee,
                transactionFee:club.transactionFee,
                monthlyChargeDay:club.monthlyChargeDay ,
                monthlyCharge:club.monthlyCharge,
                isAffiliated: club?.isAffiliated,
            },
            channel: this.getallChannelName(),
            licenceId: licence && licence.length > 0 && licence[0].id,
            clubId: club.clubId,
            userId: user.userId,
            status: club.clubStatusId,
            checked: club.clubStatusId === 1 ? true : false,
            startDate: new Date(licence && licence.length > 0 && licence[0].startDate),
            endDate: new Date(licence && licence.length > 0 && licence[0].endDate),
            ...addressField,
            addressField: {
                streetAddress1: club.streetAddress1,
                streetAddress2: club.streetAddress2,
                city: club.city,
                state: club.state,
                zip: club.zip,
                countryId: countryId,
            },
            ...pointOfContact,
            pointOfContact: {
                ownerName: user.fullName,
                email: user.email,
                poc_country_code: user.pocCountryCode,
                pointOfPhoneNumber: user.pocMobile,
                roleId: user.roleId,
                statusId: user.statusId,
                pocSlackId:user.pocSlackId
            },
            ...addLicence,
            addLicence: {
                noOfLicence: licence && licence.length > 0 && licence[0].numberOfLicence,
            },
        },
            () => {
                this.addressData = {}
                const { addressField } = this.state
                let mergeValue = Object.keys(addressField).map((key, idx) => {
                    //console.log("data=" +addressField[key])
                    this.addressData = {
                        ...this.addressData,
                        [key]: addressField[key]
                    }
                    return addressField[key]
                })
                //console.log(this.addressData)
                // this.setAddressIntoAddressBox(this.add)
                let addTextField = document.querySelector('#addressInputValue');
                addTextField.textContent = mergeValue.join(" ")
                this.setState({
                    fillAddressData: mergeValue.join(",")
                })
            }
        )

    }

    getallChannelName = () => {
        //get all channel during edit add club
        const { channels } = this.clubEditSuccessResponse
        //const { channel } = this.state
        // const channelList = [];
        const channelNames = channels.map((data => {
            return data
        }))
        return channelNames
    }

    redirectBackScreen = () => {
        $('#addClubPopup').modal('hide');
        this.props.history.push("/club-management");
    }

    handleChangeEvent = (event) => {
        const target = event.target;
        const name = target.name;
        let value = target.value;
        if(value){
            if(name === "clubName" && value.length > 100){
                toast.warn("Club Name field can not accept more than 100 characters.")
                return
            } else if(name === "ownerName" && value.length > 100){
                toast.warn("Owner Name field can not accept more than 100 characters.")
                return
            } else if(name === "pocSlackId" && value.length > 50){
                toast.warn("SlackId field can not accept more than 50 characters.")
                return
            }else if(name === "noOfLicence" && value*1 >= 1000){
                toast.warn("Licence field can not accept more than 3 digits.")
                return
            }
        }
        if ((name === "platformFee" || name === "transactionFee") && value > 100) {
            return
        }
        if (name === "platformFee") {
            value = value.length > 3 ? value.slice(0,5) : value
        }
        if (name === "monthlyCharge") {
            if(value*1 > 1000){
                return
            }else if(value*1 < 0.5 && value*1 !== 0){
                return
            }
            let RE = /^\d*\.?\d{0,2}$/
            if(!(RE.test(value))){
                return
            }
        }

        if (name === "isAffiliated") {
            value = target.checked;
        }

        const { orgInfo, pointOfContact, addLicence } = this.state;
        this.setState({
            submitted: false,
            errors: {},
            orgInfo: {
                ...orgInfo,
                [name]: value
            },
            pointOfContact: {
                ...pointOfContact,
                [name]: value
            },
            addLicence: {
                ...addLicence,
                [name]: value
            },
        });
    }

    /* The below function change the Active and Deactive staus. 
    */
    changeStatus = () => {
        const { checked } = this.state;
        this.setState({
            status: checked ? 2 : 1,
            checked: !checked,
        })
    }

    /* The below function add a new club.
    */
    addNewClub = (event) => {
        event.preventDefault();
        const { orgInfo, pointOfContact, addLicence, fillAddressData, addressField, channel } = this.state;
        let error = {};
        this.setState({
            submitted: true,
            errors: error
        });
        if (!orgInfo.clubName) {
            error["clubName"] = "Club Name is Required";
            return;
        } else if (!orgInfo.platformFee) {
            error["platformFee"] = "PlateForm Fee is Required";
            return;
         } //else if (!orgInfo.transactionFee ) {
        //     error["transactionFee"] = "Cents value is required";
        //     return;
        // }
        else if (!fillAddressData) {
            error["fillAddressData"] = "Address is Required";
            return;
        } else if (!orgInfo.mobile) {
            error["mobile"] = "Contact Number is Required";
            return;
        }
        else if (!pointOfContact.ownerName) {
            error["ownerName"] = "Name is Required";
            return;
        } else if (!pointOfContact.email) {
            error["email"] = "Email is Required";
            return;
        } else if (!orgInfo.currencyId) {
            error["currencyId"] = "Currency is Required";
            return;
        }
        else if (!UtilService.validateEmail(pointOfContact.email)) {
            error["email"] = "Invalid Email Id";
            return;
        }
        else if (!pointOfContact.pointOfPhoneNumber) {
            error["pointOfPhoneNumber"] = "Contact Number is Required";
            return;
        } else if (!addLicence.noOfLicence) {
            error["noOfLicence"] = "Number of License is Required";
            return;
        } else if (parseInt(addLicence.noOfLicence) < channel.length) {
            error["noOfLicence"] = "Number of license should be equal or more than twitch channel";
            return;
        }
        else if (orgInfo.monthlyCharge && !orgInfo.monthlyChargeDay) {
                error["monthlyChargeDay"] = "Monthly Schedule day is required.";
                return
         } else if (orgInfo.monthlyChargeDay && !orgInfo.monthlyCharge) {
                error["monthlyCharge"] = "Monthly Charge Amount is required.";
                return
         }
        else {

            //set countryId 
            const selectedCountryId = UtilService.getCountryId(addressField.countryId)
            //Api hitting
            this.setState({
                selectedCountryId: selectedCountryId
            }, () => {
                this.props.dispatch(clubAction(this.state, this.isClubEditApiSuccess));
            });

        }

    }

    formFieldEvent = (event) => {
        const target = event.target;
        const name = target.name;
        var value = target.value;
        if(value.length){
            if(name === 'streetAddress1' || name === 'streetAddress2'){
                // let reg = /^[\w][\w \.\'\-\#\@\&\/]*$/;
                // if(!(reg).test(value) ){
                //     return
                // } else 
                if(value.length > 100){
                    toast.warn("The Address field can only allow up to 100 characters.")
                    return
                }
            } else if(name === 'city'){
                // let reg = /^[A-Z a-z]*$/;
                // if(!(reg).test(value) ){
                //     return
                // } else 
                if(value.length > 50){
                    toast.warn("The city field can only allow up to 50 characters.")
                    return
                }

            } else if(name === 'state'){
                // let reg = /^[A-Z a-z]*$/;
                // if(!(reg).test(value) ){
                //     return
                // } else 
                if(value.length > 50){
                    toast.warn("The State field can only allow up to 50 characters.")
                    return
                }

            } else if(name === 'zip'){
                let reg = /^[A-Z a-z 0-9 \-]*$/;
                if(!(reg).test(value) ){
                    return
                } else if(value.length > 30){
                    toast.warn("The ZIP field can only allow up to 30 characters.")
                    return
                }

            }
        }
        if (name === 'countryId') {
            // console.log("countryId=" + value + " Key=" + target.key)
            // console.log(event)
        }
        const { addressField } = this.state;
        this.setState({
            submitted: false,
            errors: {},
            addressField: {
                ...addressField,
                [name]: value
            }
        });
    }

    formData = (event) => {
        event.preventDefault();
        // let addTextField = document.querySelector('#addressInputValue');
        //console.log("----------------------", addTextField.innerText)
        const { addressField } = this.state;
        let error = {};

        if (!addressField.streetAddress1) {
            error["streetAddress1"] = "Address Line 1 is Required";
        }
        else if (!addressField.city) {
            error["city"] = "City is Required";
        } else if (!addressField.state) {
            error["state"] = "State is Required";
        } else if (!addressField.zip) {
            error["zip"] = "Zip is Required";
        } else {
            this.addressData = {}
            let mergeValue = Object.keys(addressField).map((key, idx) => {
                this.addressData = {
                    ...this.addressData,
                    [key]: addressField[key]
                }
                return addressField[key]
            })
            this.setAddressIntoAddressBox(this.addressData)
        }

        this.setState({
            submitted: true,
            errors: error,
        });
    }
    cancelAddress = () => {
        this.setAddressIntoAddressBox(this.addressData)
    }

    setAddressIntoAddressBox = (addressData) => {

        let addTextField = document.querySelector('#addressInputValue');
        if (Object.keys(addressData).length > 0) {
            let mergeValue = Object.keys(addressData).map((key, idx) => {
                return addressData[key]
            })
            this.setState({
                addressField: addressData,
                fillAddressData: mergeValue.join(" ")
            })
            $('#addressFilupForm').modal('hide');
            addTextField.textContent = mergeValue.join(" ")
        } else {
            this.setState({
                addressField: initialAddressStateKey,
                fillAddressData: ""
            })
            addTextField.textContent = "+ Add address details"
        }
        $('#addressFilupForm').modal('hide');
    }

    handleChangeStartDate = date => {
        this.setState({
            startDate: date,
            endDate: new Date(new Date(date).setFullYear(new Date(date).getFullYear() + 1))
        });
    };

    handleChangeEndDate = date => {
        this.setState({
            endDate: date,
        });
    };

    /* The below function add the channel value.
    */
    addChannel = (event) => {
        const { channel } = this.state;

        if (event.keyCode === 13 || event.type === "click") {
            let error = {}
            this.setState({
                submitted: false,
                errors: error
            })
            let inputText = document.getElementById('addChannelList').value;
            if (inputText) {
                if (channel.length < 50) {
                    if (channel.indexOf(inputText) < 0) {
                        channel.push(inputText);
                    } else {
                        error["addChannelList"] = "This channel is already added.";
                    }
                } else if (!channel.length) {
                    channel.push(inputText);
                } else if (channel.length >= 50) {
                    error["addChannelList"] = "You can't add more than 50 channels";
                }
            } else {
                error["addChannelList"] = "Channel name is not added";
                return
            }

            this.setState({
                channel: channel
            })
            document.getElementById('addChannelList').value = "";
        } else {
            return
        }
    }

    /* The below function remove the channel value selected.
    */
    removeChannel = (idx) => {
        const { channel } = this.state;
        let indexOfVal = channel.indexOf(idx);
        channel.splice(indexOfVal, 1);
        this.setState({
            channel: channel
        })
    }
    sendMail = () => {
        const { pointOfContact } = this.state
        this.props.dispatch(sendMailAction(pointOfContact.email))
    }

    //***************************Channel Related Work******************* */
    saveChannel = (event) => {
        event.preventDefault();
        const { channel, channels, clubId } = this.state;
        let error = {}
        this.setState({
            submitted: true,
            errors: error
        })
        if (!channels.channelName) {
            error["channelName"] = "Channel name is required";
            return
        } else if (!channels.channelKey) {
            error["channelKey"] = "Stream key is required";
            return
        } else {
            //if channel and stream key not empty then perform add channel

            let inputText = { channel: channels.channelName, streamKey: channels.channelKey };


            if (channel.length < 50) {

                if (channel.some(channelData => channelData.channel === channels.channelName) && this.channelAction === ADD_CHANNEL) {
                    error["channelName"] = "This channel is already added.";
                    return
                } else {

                    if (this.channelAction === ADD_CHANNEL) {
                        if (this.isClubEditApiSuccess) {
                            //Add Channel During Edit Mode
                            let dataParams = {
                                channel: channels.channelName,
                                clubId: clubId,
                                streamKey: channels.channelKey
                            }
                            this.props.dispatch(addChannelAction(dataParams))
                        } else {
                            //Add channel During Register Mode
                            channel.push(inputText);
                        }

                    } else if (this.channelAction === EDIT_CHANNEL) {
                        if (this.isClubEditApiSuccess) {
                            //Edit Channel from server

                            let dataParams = {
                                channel: channels.channelName,
                                clubId: clubId,
                                streamKey: channels.channelKey,
                                id: channel[this.channelEditIndex].id
                            }
                            this.props.dispatch(editChannelAction(dataParams))
                        } else {
                            //Edit Channel locally
                            channel[this.channelEditIndex] = inputText
                        }

                    }
                    $('#addChannelpopup').modal('hide')
                }
            } else if (!channel.length) {
                channel.push(inputText);
            } else if (channel.length >= 50) {
                error["channelName"] = "You can't add more than 50 channels";
                return
            }

            this.setState({
                channel: channel,
                channels: {
                    channelName: "",
                    channelKey: ""
                }
            }, () => {
                // console.log("ChannelList", channel)
            })

        }

    }

    openAddChannelDialog = () => {
        this.channelAction = ADD_CHANNEL
        this.channelEditIndex = ""
        const { channels } = this.state;
        this.setState({
            submitted: false,
            errors: {},
            isUpdateChannel: false,
            channels: {
                ...channels,
                channelName: "",
                channelKey: ""
            }
        }, () => { $('#addChannelpopup').modal('show') });

    }

    editChannel = (arrayIndex) => {
        this.channelAction = EDIT_CHANNEL
        this.channelEditIndex = arrayIndex
        const { channel, channels } = this.state

        this.setState({
            isUpdateChannel: true,
            channels: {
                ...channels,
                channelName: channel[arrayIndex].channel,
                channelKey: channel[arrayIndex].streamKey
            }
        }, () => { $('#addChannelpopup').modal('show') });
        // console.log(channel[arrayIndex])
    }

    /* The below function delete the club records when user click on yes btn in popup.
    */
    deleteIfYes = (index) => {
        const { channel } = this.state
        if (this.isClubEditApiSuccess) {
            //In case of delete channel from server during edit info
            this.props.dispatch(deleteChannelAction(channel[index].id))
        } else {
            //In case of locally delete
            channel.splice(index, 1);
            this.setState({
                channel: channel,
            })
        }

    }

    addChannelChangeEvent = (event) => {
        const target = event.target;
        const name = target.name;
        var value = target.value;
        const { channels } = this.state;
        if(value){
            if(name === "channelName" && value.length > 100) {
                toast.warn("Channel Name feild can not accept more than 100 characters.")
                return
            } else if(name === "channelKey" && value.length > 80){
                toast.warn("Channel key feild can not accept more than 80 characters.")
                return
            }
        }
        this.setState({
            channels: {
                ...channels,
                [name]: value
            }
        });
    }


    //************************************AddChannel function Close***************** */ 

    static getDerivedStateFromProps(nextProps, prevState) {
        const isChanneList = get(nextProps, "apiReducer.isApiSuccessOther", false);
        // console.log("getDerivedStateFromProps", isChanneList)
        if (isChanneList) {
            const channelListData = get(nextProps, "apiReducer.apiSuccesDataOther", null);
            return {
                channel: channelListData.Channels,
            };
        }

        return null
    }
    //******************************License******************************************** */  
    /*
      Below function will trigger when click on addNewLicence during edit club
      */
    addLicenceClick = () => {

        if (this.state.errors.hasOwnProperty("noOfLicence")) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    'noOfLicence': ''
                }
            }))
        }

        this.actionType = ADD_LICENCE
        this.setState({
            startDate: new Date(),
            endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            noOfLicence: "",
            isEdit: false,
            isEditLicenceFieldDisable: false,
            isStartDateLicenceFieldDisable: false

        }, () => {
            this.showDialog()
        })
    }

    /*
    Below function will trigger when click on editNewLicense during edit club
    */
    editLicenceClick = (startDateinMillSec, endDateinMillSec, NoOfLicence, licenceId, licenceStatusId) => {
        this.actionType = EDIT_LICENCE
        let noOfLicenceDisable = true;
        let isStartDateLicenceFieldDisable = true;
        //if license status is active
        if (licenceStatusId === 1) {
            noOfLicenceDisable = false
            isStartDateLicenceFieldDisable = true;
        }
        //  licenceStatusId 10 for not started, 9 for expiresoon and 3 for expired
        else if (licenceStatusId === 10 || licenceStatusId === 9 || licenceStatusId === 3) {
            noOfLicenceDisable = true;
            isStartDateLicenceFieldDisable = false;
        }
        if (this.state.errors.hasOwnProperty("noOfLicence")) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    'noOfLicence': ''
                }
            }))
        }

        // isEditLicenceFieldEnable: false,
        // isStartDateLicenceFieldEnable: false,
        this.setState({
            startDate: new Date(startDateinMillSec),
            endDate: new Date(endDateinMillSec),
            noOfLicence: NoOfLicence,
            licenceId: licenceId,
            isEdit: true,
            isEditLicenceFieldDisable: noOfLicenceDisable,
            isStartDateLicenceFieldDisable: isStartDateLicenceFieldDisable
        }, () => {
            this.showDialog()
        })
    }

    /*
    Below function will trigger when click on reNewLicenceClick during edit club
    */
    reNewLicenceClick = (startDateinMillSec, endDateinMillSec, NoOfLicence, licenceId, licenceStatusId) => {
        this.actionType = RENEW_LICENCE
        var startDate = new Date(endDateinMillSec).setDate(new Date(endDateinMillSec).getDate() + 1);
        var endDate = new Date(new Date(endDateinMillSec).setFullYear(new Date(endDateinMillSec).getFullYear() + 1));
        let noOfLicenceDisable = true
        let isStartDateLicenceFieldDisable = false

        //if license has expired then show the current date on dialog
        if (licenceStatusId === 3) {
            startDate = new Date();
            endDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
        }
        else if (licenceStatusId === 9) {
            //expiresoon
            noOfLicenceDisable = true;
            isStartDateLicenceFieldDisable = false
        }
        if (this.state.errors.hasOwnProperty("noOfLicence")) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    'noOfLicence': ''
                }
            }))
        }
        this.setState({
            startDate: startDate,
            endDate: endDate,
            noOfLicence: NoOfLicence,
            licenceId: licenceId,
            isEdit: true,
            isEditLicenceFieldDisable: noOfLicenceDisable,
            isStartDateLicenceFieldDisable: isStartDateLicenceFieldDisable

        }, () => {
            this.showDialog()
        })
    }

    saveLicence = () => {
        //  event.preventDefault();
        const {
            // submitted, errors, 
            noOfLicence } = this.state;
        let error = {};
        this.setState({
            submitted: true,
            errors: error
        });
        if (noOfLicence.length === 0) {
            error["noOfLicence"] = "Number of License is Required";
        } else if (parseInt(noOfLicence) < this.state.channel.length) {
            error["noOfLicence"] = "Number of license should be equal or more than twitch channel";
        } else {
            this.hideDialog()
            this.addEditLicenceAPiHit()
        }
        if (error?.noOfLicence) {
            this.setState({
                errors: error
            })
        }

    }

    showDialog = () => {
        $('#addLicencePopup').modal('show')
    }

    hideDialog = () => {
        $('#addLicencePopup').modal('hide')
    }

    handleChangeStartDateLicence = date => {
        this.setState({
            startDate: date,
            endDate: new Date(new Date(date).setFullYear(new Date(date).getFullYear() + 1))
        }, () => {
            // console.log("DateEvent=" + this.state.startDate + " , end=" + this.state.endDate)
        });
    };

    handleChangeEndDateLicence = date => {
        this.setState({
            endDate: date,
        }, () => {
            //console.log("DateEvent end=" + this.state.startDate + " , end=" + this.state.endDate)
        });
    };

    handleChangeEventLicence = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            submitted: false,
            errors: {},
            [name]: value
        });
    }

    /*Below function is use to add and edit license Api hit
    */
    addEditLicenceAPiHit = () => {
        const { clubId, noOfLicence, startDate, endDate, isEdit, licenceId } = this.state
        //console.log("SubmitDateData=" + startDate + " ,endDate= " + endDate, ", noOfLicence=" + noOfLicence + ",clubId=" + clubId)
        let requestPaload
        if (this.actionType === ADD_LICENCE) {
            requestPaload = {
                clubId: clubId,
                endDate: UtilService.getDateIntoTimeStampInMillSec(endDate),
                numberOfLicence: noOfLicence,
                startDate: UtilService.getDateIntoTimeStampInMillSec(startDate)
            }
        }
        else if (this.actionType === RENEW_LICENCE) {
            //console.log("renewLicence=" + startDate + " ,endDate= " + UtilService.getDateIntoTimeStampInMillSec(endDate), ", noOfLicence=" + noOfLicence + ",clubId=" + clubId)
            requestPaload = {
                clubId: clubId,
                endDate: UtilService.getDateIntoTimeStampInMillSec(endDate),
                numberOfLicence: noOfLicence,
                startDate: startDate
            }
        }
        else if (this.actionType === EDIT_LICENCE) {
            requestPaload = {
                clubId: clubId,
                endDate: UtilService.getDateIntoTimeStampInMillSec(endDate),
                id: licenceId,
                numberOfLicence: noOfLicence,
                startDate: UtilService.getDateIntoTimeStampInMillSec(startDate)
            }
        }

        if (this.actionType === RENEW_LICENCE) {
            this.props.dispatch(renewLicenceAction(requestPaload, licenceId))
        }
        else
            this.props.dispatch(addEditLicenceAction(requestPaload, isEdit))
    }


    /*Below function is used to hit the license get Api 
    */
    getLicenceList = (clubId) => {
        this.props.dispatch(getLicenceList(clubId))
    }


    /* The below function delete the license records when user click on yes btn in popup.
    */
    deleteIfYesLicence = (licenceId) => {
        this.props.dispatch(deleteLicenceAction(licenceId))
    }
    //******************************License End************************************************** */
    render() {
        //console.log("AddClub", this.state)
        const {
            addLicence,
            pointOfContact,
            orgInfo,
            addressField,
            errors,
            submitted,
            checked,
            logo,
            startDate,
            endDate,
            channel,
            channels,
            isUpdateChannel,
            isEdit,
            noOfLicence,
            isEditLicenceFieldDisable,
            isStartDateLicenceFieldDisable
        } = this.state;

        const errorMessage = get(
            this.props,
            "addClubReducer.addClubErrorMsg",
            null
        );
        const addClubSuccessMsg = get(
            this.props,
            "addClubReducer.addClubSuccessResponse",
            null
        );

        const has_error = get(this.props, "ui.has_error", false);
        const isLoading = get(this.props, "ui.is_loding", false);
        const hasMsgShow = get(this.props, "ui.isShowMessage", false);
        const isChannelAdded = get(this.props, "apiReducer.editApiSuccess", false);
        const channelErrorMsg = get(this.props, "apiReducer.editApiFailData", null);
        const isChanneList = get(this.props, "apiReducer.isApiSuccessOther", false);

        if (hasMsgShow && addClubSuccessMsg !== null) {
            $('#addClubPopup').modal('show')
        }

        const isMailApiSuccess = get(this.props, "apiReducer.isMailApiSuccess", false);
        const mailApiSuccesData = get(this.props, "apiReducer.mailApiSuccesData", null);
        if (channelErrorMsg != null) {
            setTimeout(() => {
                this.props.dispatch({ type: EDIT_DELETE_RESET });
            }, 5000);
        }
        if (isMailApiSuccess) {
            setTimeout(() => {
                this.props.dispatch({ type: RESET_SEND_MAIL });
            }, 3000);
        }
        if (isChanneList && this.isClubEditApiSuccess) {
            //After recived get channel api 
            this.props.dispatch({ type: RESET })
        }
        //console.log("ChannelMsg=" + isChannelAdded + " channelmsg=" + channelAddedMsg)

        //get channel list after add channel on server
        if (isChannelAdded) {
            setTimeout(() => {
                this.props.dispatch({ type: EDIT_DELETE_RESET });
                this.props.dispatch(getChannelListAction(this.state.clubId))
                //console.log("Hit channel getting Api")
            }, 100);
        }

        //License Api operation
        const licenceListData = get(
            this.props,
            "apiReducer.apiSuccesDataListing",
            null
        );

        //License Redux data recived
        const isLicenceGetApiSuccess = get(
            this.props,
            "apiReducer.isApiSuccessListing",
            null
        );
        const isAddEditSuccessLicence = get(
            this.props,
            "apiReducer.isAddEditSuccess",
            false
        );
        const addEditFailDataLicence = get(
            this.props,
            "apiReducer.addEditFailData",
            null
        );
        // const addEditSuccesDataLicenceMsg = get(
        //     this.props,
        //     "apiReducer.addEditSuccesData",
        //     null
        // );
        if (isAddEditSuccessLicence) {
            this.props.dispatch({ type: RESET });
            this.props.dispatch(getLicenceList(this.state.clubId))
        }
        if (addEditFailDataLicence != null) {
            setTimeout(() => {
                this.props.dispatch({ type: RESET });
            }, 100);
        }
        return (
            <React.Fragment>
                <div class="addnewclub">
                    <form className="add-club-wrapper position-relative">
                        <ErrorAlert errorMessage={`${errorMessage}`} isShow={has_error} />
                        <SuccessAlert successMsg={mailApiSuccesData} isShow={isMailApiSuccess} />

                        {!this.isClubEditApiSuccess && this.clubEditSuccessResponse === null && (
                            <h3>Add new Club</h3>
                        )}
                        {this.isClubEditApiSuccess && this.clubEditSuccessResponse !== null && (
                            <h3>Update Club</h3>
                        )}

                        <div className="d-lg-flex add-club-management mb-3">
                            <OrganizationInformation
                                handleChangeEvent={this.handleChangeEvent}
                                onFileSelectedListener={this.onFileSelectedListener}
                                changeStatus={this.changeStatus}
                                orgInfo={orgInfo}
                                errors={errors}
                                logo={logo}
                                submitted={submitted}
                                fillAddressData={addressField.fillAddressData}
                                checked={checked}
                                addChannel={this.addChannel}
                                channel={channel}
                                removeChannel={this.removeChannel}
                                isEditClub={this.isClubEditApiSuccess}
                            />

                            <PointOfContact
                                handleChangeEvent={this.handleChangeEvent}
                                pointOfContact={pointOfContact}
                                errors={errors}
                                submitted={submitted}
                                isEditClub={this.isClubEditApiSuccess}
                                sendMail={this.sendMail}
                                editSlackId={this.clubEditSuccessResponse?.user?.pocSlackId}
                            />
                        </div>

                        {this.isClubEditApiSuccess && this.clubEditSuccessResponse !== null && (
                            <div className="d-flex justify-content-end mt-3 mb-3">
                                <button type="button" className="btn back-btn" onClick={() => { this.redirectBackScreen() }}>Back</button>
                                <div className="position-relative">
                                    <div className={isLoading ? "loader" : null}></div>
                                    <input type="button" onClick={this.addNewClub}
                                        className={`btn red-btn ml-3 ${isLoading ? "pointer-event disabled " : null
                                            }`}

                                        value={`${this.clubEditSuccessResponse ? 'Update' : 'Save'}`} />
                                </div>
                            </div>
                        )}


                        {!this.isClubEditApiSuccess && (
                            <LicenceManagement
                                handleChangeEvent={this.handleChangeEvent}
                                addLicence={addLicence}
                                errors={errors}
                                submitted={submitted}
                                handleChangeStartDate={this.handleChangeStartDate}
                                handleChangeEndDate={this.handleChangeEndDate}
                                startDate={startDate}
                                endDate={endDate}
                            />
                        )}
                        {this.clubEditSuccessResponse && (
                            <LicenceView
                                addLicenceClick={this.addLicenceClick}
                                editLicenceClick={this.editLicenceClick}
                                reNewLicenceClick={this.reNewLicenceClick}
                                saveLicence={this.saveLicence}
                                handleChangeStartDateLicence={this.handleChangeStartDateLicence}
                                handleChangeEndDateLicence={this.handleChangeEndDateLicence}
                                errors={errors}
                                submitted={submitted}
                                isEdit={isEdit}
                                endDate={endDate}
                                handleChangeEventLicence={this.handleChangeEventLicence}
                                startDate={startDate}
                                noOfLicence={noOfLicence}
                                licenceListData={licenceListData}
                                isLicenceGetApiSuccess={isLicenceGetApiSuccess}
                                addEditFailDataLicence={addEditFailDataLicence}
                                deleteIfYesLicence={this.deleteIfYesLicence}
                                actionType={this.actionType}
                                isEditLicenceFieldDisable={isEditLicenceFieldDisable}
                                isStartDateLicenceFieldDisable={isStartDateLicenceFieldDisable}
                            />
                        )}

                        <AddChannel
                            saveChannel={this.saveChannel}
                            editChannel={this.editChannel}
                            deleteIfYes={this.deleteIfYes}
                            addChannelChangeEvent={this.addChannelChangeEvent}
                            channel={channel}
                            channels={channels}
                            openAddChannelDialog={this.openAddChannelDialog}
                            errors={errors}
                            submitted={submitted}
                            isUpdateChannel={isUpdateChannel}
                            channelErrorMsg={channelErrorMsg}
                        >

                        </AddChannel>
                        {/* {this.clubEditSuccessResponse && (
                            <LicenceView
                                addLicenceClick={this.addLicenceClick}
                                editLicenceClick={this.editLicenceClick}
                                reNewLicenceClick={this.reNewLicenceClick}
                                saveLicence={this.saveLicence}
                                handleChangeStartDateLicence={this.handleChangeStartDateLicence}
                                handleChangeEndDateLicence={this.handleChangeEndDateLicence}
                                errors={errors}
                                submitted={submitted}
                                isEdit={isEdit}
                                endDate={endDate}
                                handleChangeEventLicence={this.handleChangeEventLicence}
                                startDate={startDate}
                                noOfLicence={noOfLicence}
                                licenceListData={licenceListData}
                                isLicenceGetApiSuccess={isLicenceGetApiSuccess}
                                addEditFailDataLicence={addEditFailDataLicence}
                                deleteIfYesLicence={this.deleteIfYesLicence}
                                isEditLicenceFieldDisable={isEditLicenceFieldDisable}
                                isStartDateLicenceFieldDisable={isStartDateLicenceFieldDisable}
                            />
                        )} */}
                        {!this.isClubEditApiSuccess && this.clubEditSuccessResponse === null && (
                            <div className="d-flex justify-content-end mt-3">
                                <button type="button" className="btn back-btn" onClick={() => { this.redirectBackScreen() }}>Back</button>
                                <div className="position-relative">
                                    <div className={isLoading ? "loader" : null}></div>
                                    <input type="button" onClick={this.addNewClub}
                                        className={`btn red-btn ml-3 ${isLoading ? "pointer-event disabled " : null
                                            }`}

                                        value={`${this.clubEditSuccessResponse ? 'Update' : 'Save'}`} />
                                </div>
                            </div>
                        )}
                    </form>

                    <form className="modal fade" id="addressFilupForm" onSubmit={this.formData} tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="d-table w-100">
                            <div className="d-table-cell">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <h3 className="modal-title text-center" id="staticBackdropLabel">Address Details</h3>
                                        <button type="button" className="close popup-close-btn" data-dismiss="modal" aria-label="Close" onClick={() => { this.cancelAddress() }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><path d="M5.314 6.829l5.671 5.671-5.671 5.67a1.071 1.071 0 001.515 1.515l5.671-5.67 5.671 5.671a1.071 1.071 0 001.515-1.515L14.015 12.5l5.671-5.671a1.071 1.071 0 00-1.516-1.515l-5.67 5.671-5.671-5.671a1.071 1.071 0 00-1.515 1.515z" transform="translate(-5 -5)" fill="#666"></path></svg>
                                        </button>
                                        <div className="modal-body p-0">
                                            <div className="form-group">
                                                <label>Street Address <sup className="star">*</sup></label>
                                                <div className="position-relative">
                                                    <input
                                                        type="text"
                                                        className={`form-control mb-3 ${submitted && errors["streetAddress1"]
                                                            ? "input-error"
                                                            : ""
                                                            }`}
                                                        placeholder="Address Line 1"
                                                        name="streetAddress1"
                                                        value={addressField.streetAddress1}
                                                        onChange={this.formFieldEvent}
                                                    />

                                                    {submitted && errors["streetAddress1"] && (
                                                        <ValidationMessage message={errors["streetAddress1"]} />
                                                    )}
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Address Line 2"
                                                        name="streetAddress2"
                                                        value={addressField.streetAddress2}
                                                        onChange={this.formFieldEvent}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>City<sup className="star">*</sup></label>
                                                <div className="position-relative">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${submitted && errors["city"]
                                                            ? "input-error"
                                                            : ""
                                                            }`}

                                                        placeholder="Enter city name"
                                                        name="city"
                                                        onKeyPress={(e) => UtilService.alphabetsValidation(e)}
                                                        onPaste={(e) => UtilService.onPastRestriction(e)}
                                                        value={addressField.city}
                                                        onChange={this.formFieldEvent}
                                                    />
                                                    {submitted && errors["city"] && (
                                                        <ValidationMessage message={errors["city"]} />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="d-flex state-zip-code">
                                                <div className="form-group mr-1">
                                                    <label>State/Province/County<sup className="star">*</sup></label>
                                                    <div className="position-relative">
                                                        <input
                                                            type="text"
                                                            onKeyPress={(e) => UtilService.alphabetsValidation(e)}
                                                            onPaste={(e) => UtilService.onPastRestriction(e)}
                                                            className={`form-control ${submitted && errors["state"]
                                                                ? "input-error"
                                                                : ""
                                                                }`}

                                                            placeholder="Enter name"
                                                            name="state"
                                                            value={addressField.state}
                                                            onChange={this.formFieldEvent}
                                                        />
                                                        {submitted && errors["state"] && (
                                                            <ValidationMessage message={errors["state"]} />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="form-group ml-1">
                                                    <label>ZIP/postal Code<sup className="star">*</sup></label>
                                                    <div className="position-relative">
                                                        <input
                                                            type="text"
                                                            className={`form-control ${submitted && errors["zip"]
                                                                ? "input-error"
                                                                : ""
                                                                }`}
                                                            placeholder="Enter ZIP/postal code"
                                                            name="zip"
                                                            value={addressField.zip}
                                                            onChange={(e) => {
                                                                if (constant.ALPHA_NUMERIC.test(e.target.value)) {
                                                                    this.formFieldEvent(e);
                                                                }
                                                            }}
                                                        />
                                                        {submitted && errors["zip"] && (
                                                            <ValidationMessage message={errors["zip"]} />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Country<sup className="star">*</sup></label>
                                                <CountryCodeSelect isCountryNameSorting={true} value={addressField.countryId} className="form-control" name="countryId" selectionKey={constant.COUNTRY_NAME} onSelectChange={this.formFieldEvent} />
                                            </div>
                                        </div>
                                        <div className="modal-footer p-0 mt-4 border-none">
                                            <button type="button" className="btn cancel-btn" data-dismiss="modal" onClick={() => { this.cancelAddress() }}>Cancel</button>
                                            <input type="submit" className="btn red-btn ml-3" value="Ok" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal fade" id="addClubPopup" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="d-table w-100">
                        <div className="d-table-cell">
                            <div className="modal-dialog  modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-body p-0 border-0 mb-3">
                                        <p className="text-center">
                                            {addClubSuccessMsg}
                                        </p>
                                    </div>
                                    <div className="modal-footer p-0 border-0 justify-content-center">
                                        <button
                                            className="btn login-btn pl-4 pr-4 m-0"
                                            onClick={() => { 
                                                this.props.dispatch({ type: ADD_CLUB_START });
                                                this.redirectBackScreen() }}
                                        >
                                            OK, Thanks!
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
    componentWillUnmount() {
        this.props.dispatch(ResetAction());
        this.props.dispatch({ type: CLUB_EDIT_RESET });
        $('#addClubPopup').modal('hide');
    }
}

const mapStateToProps = state => ({
    countryApiReducer: state.countryApiReducer,
    ui: state.ui,
    addClubReducer: state.addClubReducer,
    editClubReducer: state.editClubReducer,
    apiReducer: state.apiReducer
});

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
});


export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AddClub);