import React, { Component } from 'react'
import API from "../utils/api";
import * as moment from 'moment';
import config from "../../constants/config";
import constant from "../../constants/constant";
import { FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon } from "react-share";
import { cloneDeep } from 'lodash';

export class tournamentInfo extends Component {

    state = {
        tournamentFixture: [],
        numberOfRounds: [],
        roundName: '',
        tournamentFrame: null,
        showSocialIcons: false,
        winner: null,
        runnerUp: null,
        // showSocialIcons: false,
        tournamentName: '',
        isLoading: true
    }


    async componentDidMount() {
        this.setTournamentResult();

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.updateData && prevProps.updateData !== this.props.updateData) {
            this.setTournamentResult();
        }
    }

    setTournamentResult = () => {
        if (this.props.data?.detail) {
            let response = this.props.data?.detail;
            if (response) {
                this.setData(response);
            }
        } else {
            this.onLoadFn();
        }
    }

    setData = (response) => {
        const numberOfRounds = cloneDeep(response.Rounds) || null;
    
        if (numberOfRounds?.length > 0) {
            numberOfRounds.forEach(round => {
                round.Matches = round.Matches?.filter(
                    match =>
                        (match.playerId1 && match.playerId2) ||
                        (match.playerName1 && match.playerName2)
                ) || [];
            });
    
            numberOfRounds.forEach(round => {
                if (round.roundName === 'Final') {
                    round.Matches.forEach(match => {
                        if (match.winner) {
                            const isPlayer1Winner = match.winner === match.playerId1;
                            this.setState({
                                winner: isPlayer1Winner ? match.playerName1 : match.playerName2,
                                runnerUp: isPlayer1Winner ? match.playerName2 : match.playerName1,
                            });
                        }
                    });
                }
            });
        }
    
        const { Rounds, tournamentFrame, name } = response;
        this.setState({
            numberOfRounds,
            roundName: Rounds?.[0]?.roundName || null,
            tournamentFrame,
            tournamentName: name,
            isLoading: false,
        });
    };    

    onLoadFn = async () => {
        try {
            const tournamentId = this.props.tournamentId || this.props.match.params.tournamentId;
            const response = await API.getRequestWithoutAuthToken(`${config.BASE_URL}/tournament/${tournamentId}`);
            if (response) {
                this.setData(response);
            }
        } catch (error) {
            console.error(error);
        }
    }

    showSocialHandler = () => {
        this.setState({
            ...this.state,
            showSocialIcons: true
        })
    }

    hideSocialIcons = () => {
        this.setState({
            ...this.state,
            showSocialIcons: false
        })
    }

    render() {
        const tournamentId = this.props.tournamentId || this.props.match.params.tournamentId;

        return (
            <div className="tournament-fixture-container">
                {
                    this.state.numberOfRounds.length > 0 && <div className="tournament-fixture-heading">
                        <div className="fixture-heading-text">{this.state.tournamentName}</div>
                        <div className="fixture-champion-container">
                            {
                                this.state.winner ? <div className="fixture-winner">
                                    <h3>{this.state.winner}</h3>
                                    <div className="text-center">Winner</div>
                                </div>
                                    : <div className="fixture-winner">
                                        <h3>Winner Yet To Decide</h3>
                                    </div>
                            }
                            <div className="fixture-champion-divider" />
                            {
                                this.state.runnerUp ? <div className="fixture-runnerup">
                                    <h3>{this.state.runnerUp}</h3>
                                    <div className="text-center">Runner-Up</div>
                                </div>
                                    : <div className="fixture-runnerup">
                                        <h3>Runner Up Yet To Decide</h3>
                                    </div>
                            }
                        </div>
                        <div className="d-flex align-items-center share-icons-container" onMouseLeave={this.hideSocialIcons}>
                            <div className="fixture-share-icon">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22.5" height="24" viewBox="0 0 22.5 24" onMouseEnter={this.showSocialHandler}>
                                        <g id="noun_Share_2178964" opacity="0.7">
                                            <path id="Path_5401" fill="#000" d="M18.214 0a4.3 4.3 0 0 0-4.286 4.286 4.235 4.235 0 0 0 .152 1.109L7.651 9.36a4.286 4.286 0 1 0 0 5.281l6.431 3.965a4.3 4.3 0 1 0 .769-1.531l-6.43-3.965a4.134 4.134 0 0 0 0-2.218l6.43-3.965A4.282 4.282 0 1 0 18.214 0zm0 1.714a2.572 2.572 0 1 1-2.572 2.572 2.559 2.559 0 0 1 2.572-2.572zM4.285 9.428A2.572 2.572 0 1 1 1.714 12a2.559 2.559 0 0 1 2.571-2.572zm13.929 7.714a2.572 2.572 0 1 1-2.572 2.572 2.559 2.559 0 0 1 2.572-2.572z" data-name="Path 5401" />
                                        </g>
                                    </svg>
                                </span>
                            </div>
                            {
                                this.state.showSocialIcons ?
                                    <div className="share-icons">
                                        <FacebookShareButton url={`${document.location.origin}/tournamentInfo/${tournamentId}`}>
                                            <FacebookIcon logofillcolor="white" size={32} />
                                        </FacebookShareButton>
                                        <TwitterShareButton url={`${document.location.origin}/tournamentInfo/${tournamentId}`}>
                                            <TwitterIcon logofillcolor="white" size={32} />
                                        </TwitterShareButton>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                }
                {
                    (this.state.numberOfRounds && this.state.numberOfRounds.length > 0) ?
                        this.state.numberOfRounds.map((fixture, index) => {
                            return (
                                <div className="fixture-card-item" key={`${fixture.roundName}${index}`}>
                                    <div className="fixture-container bg-gray position-relative">
                                        <div className="d-md-flex justify-content-between flex-wrap align-items-start">
                                            <div className="fixture-frame-text">
                                                <h4>{this.props.tournamentStyle === constant.ROUNDROBIN ? (`Group ${(index <= 25 ? String.fromCharCode(65 + index) : constant.GroupN_32[index - 26])}`) : fixture.roundName}</h4>
                                            </div>
                                        </div>
                                        {
                                            (fixture.Matches && fixture.Matches.length > 0) ?
                                                fixture.Matches.map(match => {
                                                    // if(this.props.tournamentStyle === constant.ROUNDROBIN){
                                                    //     let arrName = match.matchName.split(' ')
                                                    //     arrName.splice(1,1,index <= 25 ? String.fromCharCode(65 + index):constant.GroupN_32[index-26])
                                                    //     match.matchName = arrName.join(' ');
                                                    // }

                                                    return (
                                                        <div className="d-md-flex bg-green-child fixture-detail-wrappper mt-3" key={match.matchId}>
                                                            <div className="d-flex justify-content-between text-center align-items-center mb-3 mb-md-0 fixture-player-1">
                                                                <div className="fixture-best-of">
                                                                    <span className="d-block text-left mb-1">Date & Time</span>
                                                                    <span className="d-block text-left mb-1 fixture-text-bold">{moment.tz(match.scheduleTime, this.props.timeZone).format('DD-MMM-YYYY') + ' ' + moment.tz(match.scheduleTime, this.props.timeZone).format("hh:mm A")}</span>
                                                                </div>
                                                                <div className="player">{match.playerName1}</div>
                                                            </div>
                                                            <div className="best-of fixture-player-frames">
                                                                <span className="d-block text-left mb-1 text-uppercase best-of-text">Best of</span>
                                                                <span className="d-block text-left mb-1 fixture-text-bold">{`${match.player1Frames}  (${match.frame})  ${match.player2Frames}`}</span>
                                                            </div>
                                                            <div className="d-flex justify-content-between text-center align-items-center mb-3 mb-md-0 fixture-player-2">
                                                                <div className="player">{match.playerName2}</div>
                                                                <div className="fixture-best-of">
                                                                    <span className="d-block text-left mb-1">{match.matchName}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : null
                                        }
                                    </div>
                                </div>
                            )
                        })
                        : null
                }

                {
                    this.state.isLoading && <div className="spinner position-relative no-record">
                        <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                            <circle className="length" fill="none" strokeWidth="8" strokeLinecap="round" cx="33" cy="33" r="28"></circle>
                        </svg>
                        <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                            <circle fill="none" strokeWidth="8" strokeLinecap="round" cx="33" cy="33" r="28"></circle>
                        </svg>
                        <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                            <circle fill="notext-centerne" strokeWidth="8" strokeLinecap="round" cx="33" cy="33" r="28"></circle>
                        </svg>
                        <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                            <circle fill="none" strokeWidth="8" strokeLinecap="round" cx="33" cy="33" r="28"></circle>
                        </svg>
                    </div>
                }
            </div>
        )
    }
}

export default tournamentInfo
